import styled from "styled-components";

const Section = styled.div`
    margin: 15px;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;
`;

const SectionTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    color: #707070;
    margin-bottom: 10px;
`;

const Para = styled.div`
    color: #707070;
    line-height: 1.8;
`;

const List = styled.ul`
    margin-top: 15px;
`;

const ListItem = styled.li``;

export {
    Section,
    SectionTitle,
    Para,
    List,
    ListItem,
}