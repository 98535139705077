import styled from "styled-components";

const Top = styled.div`
    color: #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Raleway', sans-serif;
`;

const Title = styled.h1`
    font-size: 45px;
    text-align: center;
    margin-bottom: 40px; 
    margin-top: 9%;
    letter-spacing: 16.5px;
    line-height: initial;
    padding-left: 5%;
    padding-right: 5%;
    font-weight: normal;
    @media (max-width: 768px) {
        margin-top: 100px;
        font-size: 40px;
    }
`;

const TopImg = styled.div`
    height: 55vh;
    width: 80%;
    margin: 20px;
    margin-top: 40px; 
    background-size: cover;
    background-size: cover;
    background-position-x: center;
`;

const Dot = styled.div`
    height: 8px;
    width: 8px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    border: solid #707070;
    border-width: 1px;
    margin-left: 18px;
    margin-right: 18px;
`;

const Content = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    color: #707070;
    background-size: cover;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    width: 80%;
    justify-content: space-between;
    margin-left: 10%;
    margin-top: 35px;
    margin-bottom: 35px;
    @media (max-width: 768px) {
        width: 95%;
        margin-left: 2.5%;
    }
`;

const TitleGroup = styled.div`
    display: flex;
    flex-direction: row;
    aling-constent: center;
    justify-content: flex-start;
    align-selft: center;
`;

const Number = styled.div`
    font-size: 35px;
    border: solid thin #707070;
    -moz-border-radius: 50%; /* or 50% */
    border-radius: 50%; /* or 50% */
    padding: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 20px;
`;

const InfoTitle = styled.div`
    font-size: 34px;
    align-self: center;
    margin-left: 20px;
    margin-bottom: 16px;
`;

const Para = styled.div`
    font-size: 20px;
    text-align: left;
    padding-right: 10%;
    border-left: solid thin #707070;
    margin-left: 20px;
    padding-left: 40px;
    min-height: 200px;
`;

const InfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;
    min-height: 180px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 5%;
    }
`;

const ImageBlock = styled.img`
    margin: 0;
    padding: 25px;
    margin-bottom: 1%;
    margin-right: 2%;
    opacity: .3;
    max-height: 300px;
    max-width: 22%;
    @media (max-width: 768px) {
        display: none;
    }
`;


export {
    Top,
    Title,
    TopImg,
    Dot,
    Content,
    InfoTitle,
    Para,
    ImageBlock,
    TitleGroup,
    Number,
    InfoBlock,
}