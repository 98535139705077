import React from "react"
import { graphql} from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Top, Title, Dot, Content} from "../styles/processStyles"
import {Section, SectionTitle, Para, List, ListItem} from "../styles/policyStyles"
import ContactForm from "../components/contact"

export default class Policy extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        submitted: false
      }
    }
    
  render() {
    return (
        <Layout>
            <Seo title="Privacy Policy" />
            <Top>
            <Title>PRIVACY POLICY</Title>
            <div><Dot/><Dot/><Dot/></div>
            </Top>
            <Content>
            <Section>
                <SectionTitle>Our Privacy Policy</SectionTitle>
                <Para>This privacy policy sets out how Jake on the Lakes uses and protects any information that you give us when you use our website.

                Jake on the Lakes is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.

                Jake on the Lakes may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</Para>
            </Section>
            <Section>
                <SectionTitle>What We Collect</SectionTitle>
                <Para>We may collect the following information:</Para>
                <List>
                    <ListItem>name and job title</ListItem>
                    <ListItem>demographic information such as postal code, preferences and interests</ListItem>
                    <ListItem>contact information including email address</ListItem>
                    <ListItem>other information relevant to customer surveys and/or offers</ListItem>
                </List>
            </Section>
            <Section>
                <SectionTitle>What we do with the information we gather?</SectionTitle>
                <Para>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</Para>
                <List>
                    <ListItem>Internal record keeping</ListItem>
                    <ListItem>We may use the information to improve our products and services</ListItem>
                    <ListItem>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided</ListItem>
                    <ListItem>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customize the website according to your interests</ListItem>
                </List>
            </Section>
            <Section>
                <SectionTitle>Security</SectionTitle>
                <Para>We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</Para>
            </Section>
            <Section>
                <SectionTitle>Links to Other Websites</SectionTitle>
                <Para>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</Para>
            </Section>
            <Section>
                <SectionTitle>Controlling Your Personal Information</SectionTitle>
                <Para>You may choose to restrict the collection or use of your personal information in the following ways:</Para>
                <List>
                    <ListItem>whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</ListItem>
                    <ListItem>if you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us with the form below</ListItem>
                </List>
                <Para>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.<br/><br/>

                        You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. If you would like a copy of the information held on you please contact us by using the form below.<br/><br/>

                        If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.</Para>
            </Section>
            </Content>
            <ContactForm data={this.props.data} />
        </Layout>
        )
    }
}

export const query = graphql`
query {
  webformWebform(drupal_internal__id: { eq: "9gk5ucvtcrek9dgqtqq28a8fkvvhkdb2" }) {
    drupal_internal__id
    elements {
        name
        type
        attributes {
          name
          value
        }
    }
  }
}
`

